/** begin variables **/

$red-gradient: linear-gradient(180deg, #FF5876 0%, #FF5170 24.48%, #FF7089 74.48%, #FF6F6F 100%);
$yellow-gradient: linear-gradient(180deg, #FFE193 0%, rgba(255, 214, 134, 0.951028) 28.64%, #FFDC97 76.04%, #FFDD87 99.48%);
$transparent-yellow-gradient: linear-gradient(180deg, #ffe093 0%, rgba(255, 215, 134, 0.938) 28.64%, #ffdc97ea 76.04%, #ffdd87bc 99.48%);
$blue-gradient: linear-gradient(180deg, #84A6FF 0%, #8388FE 100%);
$green-gradient: linear-gradient(180deg, #5BDFA0 0.01%, #4ADC9F 25.97%, #44D891 75.52%, #4CD091 100%);
$dark-gray-gradient: radial-gradient(82.83% 82.83% at 50% 17.17%, #E5E8F8 26.52%, #EEEFF8 100%);
$transparent-dark-gray-gradient: radial-gradient(82.83% 82.83% at 50% 17.17%, #e5e8f8ef 26.52%, #eeeff8ef 100%);
$transparent-gray-gradient: radial-gradient(82.83% 82.83% at 50% 17.17%, #f1f3fcef 26.52%, #f0f1ffe0 100%);
$gray-gradient: radial-gradient(82.83% 82.83% at 50% 17.17%, #F1F3FC 26.52%, #F0F1FF 100%);
$bubble-gradient: radial-gradient(82.83% 82.83% at 50% 17.17%, #F8F9FF 26.52%, #F6F6FB 100%);
$bubble-white-gradient: radial-gradient(82.83% 82.83% at 50% 17.17%, #ffffff 32%, #f3f0ff 100%);

$filter-gray-shadow: drop-shadow(0px 51px 74px rgba(94, 89, 105, 0.0904136)) drop-shadow(0px 23.5788px 34.2124px rgba(94, 89, 105, 0.130627)) drop-shadow(0px 13.4913px 19.5756px rgba(94, 89, 105, 0.161018)) drop-shadow(0px 8.18911px 11.8822px rgba(94, 89, 105, 0.188982)) drop-shadow(0px 4.9343px 7.15957px rgba(94, 89, 105, 0.219373)) drop-shadow(0px 2.74774px 3.98691px rgba(94, 89, 105, 0.259586)) drop-shadow(0px 1.18178px 1.71474px rgba(94, 89, 105, 0.35));
$filter-small-gray-shadow: drop-shadow(0px 8.18911px 11.8822px rgba(144, 127, 181, 0.188982)) drop-shadow(0px 4.9343px 7.15957px rgba(144, 127, 181, 0.219373)) drop-shadow(0px 2.74774px 3.98691px rgba(144, 127, 181, 0.259586)) drop-shadow(0px 1.18178px 1.71474px rgba(144, 127, 181, 0.35));
$small-gray-shadow: 0px 51px 74px rgba(144, 127, 181, 0.0904136), 0px 23.5788px 34.2124px rgba(144, 127, 181, 0.130627), 0px 13.4913px 19.5756px rgba(144, 127, 181, 0.161018), 0px 8.18911px 11.8822px rgba(144, 127, 181, 0.188982), 0px 4.9343px 7.15957px rgba(144, 127, 181, 0.219373), 0px 2.74774px 3.98691px rgba(144, 127, 181, 0.259586), 0px 1.18178px 1.71474px rgba(144, 127, 181, 0.35);
$tiny-gray-shadow: 0px 8.18911px 11.8822px rgba(144, 127, 181, 0.188982), 0px 4.9343px 7.15957px rgba(144, 127, 181, 0.219373), 0px 2.74774px 3.98691px rgba(144, 127, 181, 0.259586), 0px 1.18178px 1.71474px rgba(144, 127, 181, 0.35);
$gray-shadow: 0px 51px 74px rgba(144, 127, 181, 0.0904136), 0px 23.5788px 34.2124px rgba(144, 127, 181, 0.130627), 0px 13.4913px 19.5756px rgba(144, 127, 181, 0.161018), 0px 8.18911px 11.8822px rgba(144, 127, 181, 0.188982), 0px 4.9343px 7.15957px rgba(144, 127, 181, 0.219373), 0px 2.74774px 3.98691px rgba(144, 127, 181, 0.259586), 0px 1.18178px 1.71474px rgba(144, 127, 181, 0.35);
$light-gray-shadow: 0px 51px 74px rgba(215, 208, 231, 0.09), 0px 23.5788px 34.2124px rgba(215, 208, 231, 0.130627), 0px 13.4913px 19.5756px rgba(215, 208, 231, 0.161018), 0px 8.18911px 11.8822px rgba(215, 208, 231, 0.188982), 0px 4.9343px 7.15957px rgba(215, 208, 231, 0.219373), 0px 2.74774px 3.98691px rgba(215, 208, 231, 0.259586), 0px 1.18178px 1.71474px rgba(215, 208, 231, 0.35);

$shine-shadow: 0px -1px 8px rgba(255, 255, 255, 0.15), 0px 51px 74px rgba(144, 127, 181, 0.0904136), 0px 23.5788px 34.2124px rgba(144, 127, 181, 0.130627), 0px 13.4913px 19.5756px rgba(144, 127, 181, 0.161018), 0px 8.18911px 11.8822px rgba(144, 127, 181, 0.188982), 0px 4.9343px 7.15957px rgba(144, 127, 181, 0.219373), 0px 2.74774px 3.98691px rgba(144, 127, 181, 0.259586), 0px 1.18178px 1.71474px rgba(144, 127, 181, 0.35);

$green-color: #078B5C;

$sidebar-width: 120px;
$sidebar-expanded-width: 270px;

/** begin mixins **/
@mixin mobile {
    @media(max-width: 767px){
        @content;
    }
}
@mixin tablet {
    @media(min-width: 768px) and (max-width: 990px){
        @content;
    }
}
/** end mixins **/

/** begin helpers **/
* {
  letter-spacing: 0.04rem;
  box-sizing: border-box;
  font-family: nimbus-sans, 'Helvetica Neue', Helvetica, sans-serif;
}

*:focus {
  outline: none;
}

body {
  font-family: nimbus-sans, 'Helvetica Neue', Helvetica, sans-serif;
  --border-color: #ddd6eb;
  --dark-border-color: #ab9bd1;
  --dark-title-color: #71629e;
  --dark-blue-color: #5b31be;
  --yellow-color: #fc497a;
  --sidebar-width: 120px;
}

.row{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  	margin: 0;
}
.column{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}
.mobile-hide{
    display: initial;
    @include mobile{
        display: none;
    }
}
.desktop-hide{
    display: none;
    @include mobile{
        display: initial;
    }
}

.no-decoration {
  text-decoration: none;
}

/** end helpers **/

/** begin nav **/

.loading {
  z-index: 10000;
  width: 200px;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 12px 22px;
  font-size: 22px;
  border: 1px solid #734ec3;
  background: $blue-gradient;
  box-shadow: $shine-shadow;
  border-radius: 32px;
  color: white;
  text-shadow: 0 0 4px rgba(255,255,255, 0.75);
  animation: crotate 6s linear infinite;
  opacity: 1.0;
  transition: 1.5s;
}

.loading.done {
  opacity: 0.0;
  transform: translate(-50%, calc(-50% - 20px));
  animation: dnone 0.01s linear;
  animation-delay: 1.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes dnone {
  0% {
    transform: translate(-50%, calc(-50% - 20px));
  }
  100% {
    transform: translate(-50%, calc(-50% - 20px)) scaleY(0);
  }
}

@keyframes appear {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1.0;
  }
}

@keyframes crotate {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}

.App__view-cart-wrapper {
  position: fixed;
  top: 0;
  right:0;
  z-index:150;
  
  .App__view-cart {
    position: relative;
    background: none;
    border: none;
    cursor: pointer;
    width: 112px;
    padding: 18px 20px;
    transition: 0.2s;
    .count {
      position: absolute;
      top: 18px;
      right: 19px;
      background: $green-gradient;
      border: 1px solid $green-color;
      font-size: 14px;
      font-weight: 600;
      color: $green-color;
      width: 24px;
      height: 24px;
      border-radius: 20px;
      box-shadow: $tiny-gray-shadow;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:hover {
      transform: translateY(-8px);
    }
    img {
      width: 100%;
      border: 0.5px solid #7b3feb;
      border-radius: 36px;
      box-shadow: $tiny-gray-shadow;
    }
  }
}

.App__sidebar{
    position: fixed;
    left: -3px;
    top: -1px;
    height: calc(100% + 2px);
    width: $sidebar-width;
    background: linear-gradient(90deg, #F7F3FF 0%, #EEE9FA 98.44%);
    //box-shadow: 140px 0px 328px rgba(112, 97, 138, 0.0562291), 58.49px 0px 137.031px rgba(112, 97, 138, 0.0807786), 31.27px 0px 73.2631px rgba(112, 97, 138, 0.1), 17.53px 0px 41.0707px rgba(112, 97, 138, 0.119221), 9.31px 0px 21.8123px rgba(112, 97, 138, 0.143771), 3.87px 0px 9.0766px rgba(112, 97, 138, 0.2);
    display: flex;
    flex-direction: column;
    transition: 0.4s;
    z-index: 100000;
    .expanded-detail{
        display: flex;
        opacity: 0;
        left: -1000px;
        font-size: 14px;
        transition-delay: 0.4s;
        transition-duration: 0.4s;
        > .a, > a {
          opacity: 0;
          transition-delay: 0.4s;
          transition-duration: 0.4s;
        }
    }
    &:hover{
        width: $sidebar-expanded-width;
        .image-section {
          height: 0px;
          min-height: 0px;
        }
        .expanded-detail{
            top: 44px;
            // display: flex;
            visibility: visible;
            flex-wrap: wrap;
            position: absolute;
            justify-content: space-between;
            right: 10px;
            left:unset;
            width: 158px;
            opacity: 1.0;
            transition-delay: 0;
            transition-duration: 0;
            > .a, > a {
                width: 79px;
                margin-bottom: 14px;
                text-align: center;
                color: inherit;
                opacity: 0.75;
                cursor:pointer;
                &:hover {
                  opacity: 1.0;
                }
                &.active {
                  opacity: 1.0;
                }
            }
        }
    }

    .top-section{
        background: $red-gradient;
        min-height: 140px;
        display: flex;
  
        align-items: flex-start;
        position: relative;
        --result: calc(var(--sidebar-width) - 76px);
        padding: 0px calc(var(--result) / 2);


        border-bottom: 0.5px solid #74000A;
        .expanded-detail {
          position: absolute;
          bottom: -8px;
          top: unset;
          right: 26px;
          width: 104px;
          path, rect {
            stroke: none;
          }
          @media (max-width: 767px) {
            .m-hide {
              display:none;
            }
          }
        }

        .sidebar-title{
            color: white;
            width: 86px;
            height: 20px;
            background: linear-gradient(180deg, #FF4975 0%, #F12E46 100%);
            border: 0.5px solid #97233F;
            box-shadow: 0px 51px 74px rgba(229, 28, 28, 0.0904136), 0px 23.5788px 34.2124px rgba(229, 28, 28, 0.130627), 0px 13.4913px 19.5756px rgba(229, 28, 28, 0.161018), 0px 8.18911px 11.8822px rgba(229, 28, 28, 0.188982), 0px 4.9343px 7.15957px rgba(229, 28, 28, 0.219373), 0px 2.74774px 3.98691px rgba(229, 28, 28, 0.259586), 0px 1.18178px 1.71474px rgba(229, 28, 28, 0.35);
            border-radius: 10.5px;
            position: absolute;
            --result: calc(var(--sidebar-width) - 86px);
            left: calc(var(--result) / 2);
            text-align: center;
            font-size: 12px;
            line-height: 18px;
            bottom: 12px;
            text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.75), 0px 0px 6px rgba(255, 255, 255, 0.75);
            letter-spacing: 0.015em;
        }

        .tienda {
          display:flex;
          width: 76px;
          height: 76px;
          justify-content: center;
          align-items: baseline;
          color: #FF1C1C;
          text-stroke: 0.5px solid #B4193E;
          margin: 18px auto;
          margin-bottom: 24px;
          text-shadow: 0px 51px 74px rgba(138, 127, 162, 0.0904136), 0px 23.5788px 34.2124px rgba(138, 127, 162, 0.130627), 0px 13.4913px 19.5756px rgba(138, 127, 162, 0.161018), 0px 8.18911px 11.8822px rgba(138, 127, 162, 0.188982), 0px 4.9343px 7.15957px rgba(138, 127, 162, 0.219373), 0px 2.74774px 3.98691px rgba(138, 127, 162, 0.259586), 0px 1.18178px 1.71474px rgba(138, 127, 162, 0.35);
          background-color: #e7e9f3;
          font-family: europa;
          font-style: normal;
          font-weight: bold;
          font-size: 69px;
          line-height: 67px;
          font-weight: 900;
          text-decoration: none;
          transition: 0.2s;
          border-radius: 100px;
          background: radial-gradient(73.26% 73.26% at 50% 26.74%, #FFFFFF 64.27%, #EFE8FF 100%);
          box-shadow: 0px 0px 11px rgba(255, 255, 255, 0.85), 0px 51px 74px rgba(229, 28, 28, 0.0904136), 0px 23.5788px 34.2124px rgba(229, 28, 28, 0.130627), 0px 13.4913px 19.5756px rgba(229, 28, 28, 0.161018), 0px 8.18911px 11.8822px rgba(229, 28, 28, 0.188982), 0px 4.9343px 7.15957px rgba(229, 28, 28, 0.219373), 0px 2.74774px 3.98691px rgba(229, 28, 28, 0.259586), 0px 1.18178px 1.71474px rgba(229, 28, 28, 0.35);
          box-sizing: border-box;
          cursor: pointer;
          &:hover {
            filter: hue-rotate(-10deg) brightness(1.15);
          }
        }
    }
    .middle-section{
        background: $yellow-gradient;
        min-height: 156px;
        padding-top: 0px;
        width: 100%;
        position: relative;
        overflow: hidden;
        border-bottom:0.5px solid #f43d00c2;
        a {
          text-decoration: none;
        }
        .active-collection, .active-category {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          img {
            width: 190px;
            --result: calc(var(--sidebar-width) - 190px);
            left: calc(var(--result) / 2);
            object-fit: contain;
            max-width: unset;
            max-height: unset;
            position: absolute;
            bottom: -74px;
          }
        }

        .sidebar-title {
          font-size: 14px;
          line-height: 17px;          
          text-align: center;
          letter-spacing: 0.06em;
          font-style: normal;
          font-weight: normal;
          color: inherit;
          margin: 0;
          &.pill {
            padding: 3px 12px;
            border-radius: 20px;
          }
        }

        &.collection {
          color: #FF5631;
          .sidebar-title {
            &.pill {
              background-color: rgba(255, 86, 49, 0.3);
            }
          }
        }

        &:nth-child(3){
            background: $green-gradient;
            height: 155px;
            color: $green-color;
            border-color: $green-color;
            .sidebar-title{
              &.pill {
                background-color: rgba(7, 139, 92, 0.3);
              }
            }
            .active-collection {

            }
        }
        .column{
            height: 155px;
            width: $sidebar-width;
            padding-top: 12px;
            padding-bottom: 12px;
        }
    }

    .image-section {
      width: 100%;
      min-height: 0px;
      height: 100%;
      position:relative;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        opacity:0.25;
      }
      .cover {
        top: 0;
        left: 0;
        opacity: 0.25;
        position: absolute;
        width: 100%;
        height: 100%;
        content: ' ';
        background: radial-gradient(250.12% 80.22% at 50% 13.94%, rgba(115, 114, 127, 0.53) 27.94%, rgba(121, 120, 135, 0.6) 49.32%, rgba(112, 112, 123, 0.65) 63.43%, rgba(105, 105, 116, 0.82) 83.82%, #5A5A67 100%);
      }
    }

    .info-section {
      background: #515265;
      box-shadow: inset 0px 1px 15px rgba(0, 0, 0, 0.25);
      min-height: 60px;
      font-size: 12px;
      line-height: 15px;
      overflow: hidden;
      position: relative;
      .details {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: $sidebar-width;
        height: 100%;
      }
      .expanded-detail {
        position: absolute;
        right: 22px;
        width: 120px;
        top: 14px;
      }
    }
    .questions-section {
      height: 31px;
      border-bottom: 0.5px solid #2F4ABD;
      background: linear-gradient(180deg, #E9EAF9 11.98%, #CECFF1 100%);
      color: rgba(95, 82, 131, 1);
      font-size: 14px;
      padding: 6px 0px;
      position: relative;
      a {
        width: $sidebar-width;
        text-align: center;
        color: inherit;
        display: inline-block;
        text-decoration: none;
      }
      .expanded-detail {
        position: absolute;
        right: 11px;
        top: 6px;
        width: fit-content;
      }
    }
    .bottom-section {
      background: $blue-gradient;
      min-height: 133px;
      position: relative;
      overflow: hidden;
      .column{
        width: $sidebar-width;
      }
      .icon-links {
        display: flex;
        width: $sidebar-width;
        justify-content: space-evenly;
        align-items: center;
        position: absolute;
        bottom: 0;
        padding-bottom: 12px;
        img {
          width: 20px;
        }
      }
      .sun-image {
        width: 144px;
        left: 12px;
        object-fit: contain;
        max-width: unset;
        max-height: unset;
        position: absolute;
        bottom: 0px;
      }
      .expanded-detail {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        top: 18px;
        .a, a {
          text-decoration: none;
          text-align: left;
          padding-left: 20px;
          color: var(--dark-blue-color);
          width: 100%;
          margin-bottom: 6px;
        }
      }
    }

    @media(max-width: 767px){
      width: 70%;

      .App__title{
        transform: translateX(350%);
      }
      transform: translateX(-100%);


      &.active{
        
        transform: unset;
        .expanded-detail{
            top: 44px;
            display: flex;
            flex-wrap: wrap;
            position: absolute;
            justify-content: flex-end;
            right: 10px;
            width: 158px;
            font-size: 14px;
            > .a, > a {
                width: 79px;
                margin-bottom: 14px;
                text-align: center;
                color: inherit;
                opacity: 0.75;
                cursor:pointer;
                &:hover {
                  opacity: 1.0;
                }
                &.active {
                  opacity: 1.0;
                }
            }
        }
        .App__title{
          transform: unset;
        }
        .info-section {
          .expanded-detail {
            position: absolute;
            right: 22px;
            width: 120px;
            top: 14px;
          }
        }
        .top-section {
          min-height: 114px;
          .sidebar-title {
            display: none;
          }
          .expanded-detail {
            top: unset;
            bottom: 0.5px;
          }
        }
        .bottom-section {
          .expanded-detail {
            top: 18px;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            .a, a {
              width: 100%;
              margin-bottom: 6px;
              text-align: left;
              padding-left: 20px;
              color: var(--dark-blue-color);
            }
          }
        }
    }

    }

}
/** end nav **/

/** begin hero **/
.hero-carousel {
    height: calc(100vh - 57px);
    position: relative;
    margin-left: calc(var(--sidebar-width) - 3px);
    --adjusted-width: calc(var(--sidebar-width) - 3px);
    width: calc(100vw - var(--adjusted-width));
    border-bottom: 0.5px solid #6D6089;
    z-index: 14;
    box-shadow: inset $gray-shadow;
    overflow: hidden;
    .hero-info, .carousel-box {
      &.closed {
        opacity: 0.0;
        transform: translateY(10px);
        animation: dnone 0.01s linear;
        animation-delay: 1.5s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
      }
      transition: 0.5s opacity;
      position: absolute;
      right: 30px;
      top: 30px;
      z-index: 10000;
      border: 0.5px solid #734ec3;
      background: $dark-gray-gradient;
      border-radius: 18px;
      box-shadow: $gray-shadow;
      overflow: hidden;
      width: 440px;
      .hero-icon {
        flex: 0 0 50px;
        height: 50px;
        margin-right: 16px;
        position: relative;
        img {
          position: absolute;
          width: 150px;
          left: -50px;
          bottom: -87px;
          max-height: unset;
          max-width: unset;
        }
      }
      .hero-info-title {
        cursor: move;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: $gray-gradient;
        border-bottom: 0.5px solid var(--border-color);
        padding: 12px 16px;
        font-size: 18px;
        color: #ae91f0;
        span {
          font-family: nimbus-sans-extended;
          letter-spacing: -0.04rem;
          font-size: 20px;
          font-weight: 800;
          color: #8352f5;
        }
        button {
          padding: 0;
          margin: 0;
          background: none;
          border: none;
          color: #ae91f0;
          font-size: 18px;
          cursor: pointer;
        }
      }
      .hero-info-details {
        padding: 14px 16px;
        padding-right: 20px;
        font-size: 16px;
        line-height: 1.15;
        color: #6E628A;
        display: flex;
        align-items: center;
      }
      .hero-info-line {
        width: 100%;
        border-top: 0.5px solid var(--border-color);
        font-size: 14px;
        color: #6E628A;
        display: flex;
        justify-content: space-between;
        padding: 8px 14px;
        span:last-child {
          color: #8352f5;
        }
        &.white {
          background:#e0e2f8;
          border-top: 0.5px solid #bbaae0;
        }
      }
      .email-form {
        width: 100%;
        form {
          display: flex;
          align-items: stretch;
          width: 100%;
          margin-bottom: -0.5px;
          input[type=email] {
            flex-grow: 0 1 100%;
            width: 100%;
            font-size: 15px;
            padding: 10px 14px;
            background: $bubble-gradient;
            border: none;
            border-top: 0.5px solid #bbaae0;
            margin: 0;
          }
          input[type=submit] {
            flex-grow: 0;
            font-size: 15px;
            padding: 10px 14px;
            margin: 0;
            color: white;
            border: none;
            background: $blue-gradient;
            border-left: 0.5px solid #734ec3;
            border-top: 0.5px solid #734ec3;
            cursor: pointer;
          }
        }
      }
    }
    .carousel-box {
      right: unset;
      top: 0;
      border: none;
      border-right: 0.5px solid var(--dark-border-color);
      width: calc(100% - 500px);
      height: 100%;
      position: absolute;
      box-shadow: none;
      border-radius: 0;
      background: none;
      .shadow {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        height: 100%;
      }
      .carousel-root {
        width: 100%;
        height: 100%;
      }
      .carousel {
        .control-dots .dot {
          box-shadow: none;
          background: #35353F;
          margin: 0 5px;
          &.selected {
            background: white;
            box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
          }
        }
        height: 100%;
        background: none;
      }
      .slider-wrapper {
        height: 100%;
        &::after {
          content: ' ';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: url('../static/shadow.png');
          background-size: cover;
          background-position: center bottom;
          pointer-events: none;
          opacity: 0.2;
        }
      }
      .slider {
        height: 100%;
      }
      .slide {
        background: none;
      }
      .slide img {
        object-fit: cover;
        height: 100%;
        object-position: top;
        // mix-blend-mode: multiply;
      }
      .carousel-slide {
        width: 100%;
        font-size: 14px;
        color: #6E628A;
        background: $bubble-white-gradient;
        display: flex;
        flex-direction: column;
        padding: 8px 14px;
        img {
          height: 100%;
          object-fit: cover;
        }
        .carousel-slide-line {
          // transform: translateY(10px);
          // opacity: 0;
          width: 100%;
          align-items: baseline;
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          margin-top: 5px;
          text-decoration: none;
          &.animate {
            animation: appear 0.99s forwards;
          }
          &.animate2 {
            animation: appear 1.0s forwards;
          }
        }
        .carousel-slide-title {
          color: #5a41ec;
          font-size:17px;
          text-align: left;
          text-decoration: none;
          margin-bottom: 5px;
        }

        .carousel-slide-date {
          opacity: 0.6;
          text-decoration: none;
        }

        .carousel-slide-number {
          margin-top: 8px;
          text-align: center;
          opacity: 0.4;
        }
      }
    }

    .gradient-overlay {
      mix-blend-mode: unset;
      opacity: 0.5;
      &:hover {
        opacity: 0.4;
      }
    }
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media(max-width: 767px){
      height: auto;
      min-height: 100vh;
      margin: 0;

      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 88px 0px 60px;
      .carousel-box{
        width: 100%;
        z-index: -1;
      }
      .hero-info{
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 40px);
        
        position: relative;
        margin-bottom: 64px;

      }
      .featured-products-container{
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 40px);
        top: 55%;
        position: relative;
        .badge{
          min-width: 167px;
          text-align: center;

        }
        .featured-products {
          padding-top: 44px;
          max-height: unset;


          .transparent-product{
            max-height: unset;
            
          }
        }
      }
      > img {
        display: none;
      }
    }
}
/** end hero **/

/** begin cart **/
.Cart {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 151;
  width: 320px;
  display: flex;
  flex-direction: column;
  transform: translateX(100%);
  transition: transform 0.15s ease-in-out;
  overflow:scroll;
  border-left: 1px solid var(--dark-border-color);

  @media(max-width: 767px){
    z-index: 100001;

  }
}

.Cart--open {
  transform: translateX(0);

}

.Cart__close {
  position: absolute;
  right: 9px;
  top: 9px;
  font-size: 35px;
  color: var(--dark-blue-color);
  border: none;
  background: transparent;
  transition: transform 100ms ease;
  cursor: pointer;
  @media(max-width: 767px) {
    border-radius: 12px;
    background: linear-gradient( 180deg , #6473ff 0%, #8f5eff 100%);
    border: 0.5px solid #311269;
    box-sizing: border-box;
   //box-shadow: 0px 51px 74px rgb(144 127 181 / 9%), 0px 23.5788px 34.2124px rgb(144 127 181 / 13%), 0px 13.4913px 19.5756px rgb(144 127 181 / 16%), 0px 8.18911px 11.8822px rgb(144 127 181 / 19%), 0px 4.9343px 7.15957px rgb(144 127 181 / 22%), 0px 2.74774px 3.98691px rgb(144 127 181 / 26%), 0px 1.18178px 1.71474px rgb(144 127 181 / 35%);
    width: 50px;
  }
}

.Cart__header {
    padding: 12px 20px;
    color: var(--dark-title-color);
    background: $bubble-gradient;
    font-weight: 600;
    flex: 0 0 auto;
    display: inline-block;
    border-bottom: 1px solid var(--dark-border-color);
    box-shadow: $small-gray-shadow;
    h3 {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.0;
      margin: 10px 0;
    }
}

.Cart__line-items {
  background: $gray-gradient;
  flex: 1 0 auto;
  margin: 0;
  padding: 20px;
}

.Cart__footer {
  padding: 20px;
  background: $bubble-gradient;
  border-top: 1px solid var(--dark-border-color);
  flex: 0 0 auto;
  
}

.Cart__checkout {
  margin-top: 20px;
  display: block;
  width: 100%;

  display: flex;
    padding: 0px 15px;
    align-items: center;
    justify-content: center !important;
    text-shadow: 0px 0px 6px rgba(255, 255, 25, 0.75);
  
}

.Cart-info {
  display: flex;
  justify-content: space-between;
  &.full {
    justify-content: center;
  }
}

.Cart-info__total {
  // text-transform: uppercase;
  color: var(--dark-title-color);
}

.Cart-info__small {
  font-size: 16px;
}

.Cart-info__additional {
  font-size: 14px;
  margin-top: 12px;
  text-align: center;
  opacity: 0.75;
  color: var(--dark-title-color);
}

.Cart-info__pricing {
}

.pricing {
  font-size: 16px;
  color: var(--dark-blue-color);
}

/* LINE ITEMS
 * ============================== */
.Line-item {
  margin-bottom: 20px;
  overflow: hidden;
  backface-visibility: visible;
  min-height: 65px;
  position: relative;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.Line-item__img {
  width: 65px;
  height: 65px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 3px;
  position: absolute;
}

.Line-item__img img {
  border-radius: 10px;
}

.Line-item__content {
  width: 100%;
  padding-left: 75px;
}

.Line-item__content-row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
  margin-top: 3px;
  position: relative;
  color: var(--dark-title-color);
}

.Line-item__variant-title {
  float: right;
  font-size: 13px;
  line-height: 17px;
}

.Line-item__title {
  font-size: 15px;
  font-weight: 400;
}

.Line-item__price {
  line-height: 23px;
  float: right;
  font-size: 15px;
  margin-right: 20px;
  color: var(--dark-blue-color);
}

.Line-item__quantity-container {
  border: 1px solid var(--dark-border-color);
  float: left;
  border-radius: 3px;
}

.Line-item__quantity-update {
  display: block;
  float: left;
  height: 21px;
  line-height: 16px;
  font-family: monospace;
  width: 25px;
  padding: 0;
  border: none;
  background: transparent;
  box-shadow: none;
  cursor: pointer;
  font-size: 15px;
  text-align: center;
  color: var(--dark-blue-color);
}

.Line-item__quantity-update-form {
  display: inline;
}

.Line-item__quantity {
  width: 20px;
  height: 21px;
  line-height: 23px;
  font-size: 15px;
  border: none;
  text-align: center;
  -moz-appearance: textfield;
  background: transparent;
  border-left: 1px solid var(--dark-border-color);
  border-right: 1px solid var(--dark-border-color);
  display: block;
  float: left;
  padding: 0;
  border-radius: 0;
}

.Line-item__remove {
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  background: 0;
  font-size: 20px;
  top: -2px;
  opacity: 0.5;
  padding-right:0;
}

.Line-item__remove:hover {
  opacity: 1;
  cursor: pointer;
}
/** end cart **/


/* PRODUCTS
 * ============================== */
.Product-wrapper {
  --product-height: 30vw;
  --product-info-height: 64px;
  --m-3: calc(var(--sidebar-width) - 3px);
  display: flex;
  flex-wrap: wrap;
  z-index: 1;
  margin-left: calc(var(--m-3) - 3px);
  margin-bottom: 0;
  margin-top: -3px;
  border-top:0;
  // width: calc(100vw - var(--m-3));
  justify-content: center;
  background: $gray-gradient;
  @media(max-width: 767px){

    --product-height: auto;
    margin-left: 0px;
    position: absolute;

    &:not(.products-section){
      z-index: 4;
    }
  }
}

.products-section {
  padding-bottom: 200px;
}

.product-holder {
    width: 100%;
    display: flex;
    padding: 0;
    flex-wrap: wrap;
    justify-content: flex-start;
    background: #fdfcff;
    position: relative;
    padding: 0;
    padding-bottom: 0;
}

.gradient-overlay {
  content: ' ';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: 0.1s;
  mix-blend-mode: luminosity;
  background: radial-gradient(250.12% 80.22% at 50% 13.94%, rgba(115, 114, 127, 0.53) 27.94%, rgba(121, 120, 135, 0.6) 49.32%, rgba(112, 112, 123, 0.65) 63.43%, rgba(105, 105, 116, 0.82) 83.82%, #5A5A67 100%);
  &:hover {
    opacity: 0.2;
  }
}

body :not(.ProductPage-wrapper) .Product .Product__flex {
  &:hover {
    .Product__flexline {
      height: fit-content;
      margin-bottom: 5px;
      margin-top: 16px;
    }
    .Product__info {
      z-index: 1000;
      background: $bubble-gradient;
      box-shadow: $light-gray-shadow;
      height: fit-content;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      .Product__cta {
        display: flex;
      }
      .Product__description {
        display: inline-block;
      }
      .Product__price {
        display:none;
      }
    }
  }
}

 .Product {
    flex: 0 1 25%;
    min-width:220px;
    font-size:13px;
    height: var(--product-height);
    text-decoration: none;
    color:black;
    // transition: 0.15s all;
    // margin-bottom: 20px;
    position:relative;
    // border: 0.5px solid var(--border-color);
    // box-shadow: $light-gray-shadow;
    border-radius: 0px;
    @media (max-width: 767px) {
      flex: 0 0 100%;
    }
  }
  
  .Product__flex {
    display:flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    overflow: visible;
    >img{
      height: 100%;
      width: 100%;
      object-fit:cover;
      border-left: 0.5px solid var(--border-color);
      border-bottom: 0.5px solid var(--border-color);
      // border-top-left-radius: 16px;
      // border-top-right-radius: 16px;
      transition: 0.1s;
    }

    .Product__background{
      height: calc(var(--product-height) - var(--product-info-height));
      width: 100%;
      border: 0.5px solid var(--border-color);
      border-bottom: none;
      border-top: none;
      background: white;
      background-blend-mode: screen;
      display: flex;
      align-items: center;
      justify-content: center;
      // border-top-left-radius: 16px;
      // border-top-right-radius: 16px;
      img {
        padding: 20px;
        object-fit: contain;
        background-color: white;
        width: 100%;
        // border-top-left-radius: 16px;
        // border-top-right-radius: 16px;
      }
    }



    .Product__info {
        width: 100%;
        padding: 0px 18px;
        padding-right: 16px;
        padding-bottom: 20px;
        background: $bubble-white-gradient;
        border: 0.5px solid var(--border-color);
        z-index: 10;
        height: var(--product-info-height);
        // transition: height 0.4s;
        overflow: hidden;
        position: absolute;
        top: calc(var(--product-height) - var(--product-info-height));
        // border-bottom-left-radius: 16px;
        // border-bottom-right-radius: 16px;
        .Product__description {
          color: #7458f3;
          font-size:15px;
          margin-bottom: 16px;
          display: none;
        }
        .Product__cta{
            display: none;
            flex-direction: column;
            width: 100%;
            .row{
                margin-bottom: 12px;
                position: relative;
                .button-container {
                  display: flex;
                  flex-direction: row;
                  width: 100%;
                }
                button, option, select {
                  text-transform: uppercase;
                  text-align: center;
                }
                button, select {
                    background: $bubble-white-gradient;
                    padding: 8px 14px;
                    padding-top:7px;
                    cursor: pointer;
                    border: 0.5px solid #9279BE;
                    color: var(--dark-title-color);
                    box-shadow: $small-gray-shadow;
                    box-sizing: border-box;
                    border-radius: 20px;
                    font-size: 16px;
                    text-align: center;
                    text-align-last: center;
                    flex-grow: 1;
                    margin-left: 8px;
                    transition: 0.2s;
                    &:first-child {
                      margin-left: 0;
                    }
                    &:hover {
                      filter: hue-rotate(-12deg);
                      transform: translateY(-4px);
                    }
                }
            }

            .variantSelector {
              margin-bottom: 4px;
            }

            
        }
        a{
          display: none;

        }
        @media(max-width: 767px){
          position: relative;
          height: auto;
          display:none;
          .Product__cta{
            display: block;
          }
        }
      }
  }
  
  .tienda-button{
    color: white;
    background: linear-gradient(180deg, #7481F6 0%, #7367FF 100%);
    border: 0.5px solid #311269;
    box-sizing: border-box;
    box-shadow: $small-gray-shadow;
    display: flex;
    justify-content: space-between;
    height: 44px;
    font-size: 18px;
    transition: 0.2s;
    cursor: pointer;
    padding: 0px;
    border-radius: 12px;
    overflow: hidden;
    &:hover {
      filter: hue-rotate(-12deg);
      transform: translateY(-4px);
    }
    .cta__text{
        font-size: 17.5px;
        display: flex;
        padding: 10px 17px;
        text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.5);
    }
    .price{
        background: #5ED4A1;
        color: #3E856D;
        border-left: 0.5px solid #311269;
        padding: 8px 14px;
        padding-top: 12px;
        font-size: 16px;
        height: 100%;
    }

}
  
  .Product__title {
    font-size: 15.5px;
    line-height: 1.05;
    color: var(--dark-title-color);
    border-radius: 7px;
    margin-top: 0;
    font-weight: 500;
    margin-bottom: 0px;
  }
  
  .Product__flexline{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 38px;
    width:100%;
    margin-bottom:2px;
    margin-top: 12px;
  }
  
  .Product__price {
    display: block;
    color: $green-color;
    background: $green-gradient;
    border-radius: 9px;
    border: 0.5px solid $green-color;
    box-shadow: $light-gray-shadow;
    flex: 0 0 43px;
    height: 24px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    // display: none;

    @media (max-width: 767px){
      display: none;

    }
  }

  .active .type-header {
    z-index: 10000;
  }

  .collection-image-hero {
    width: 100%;
    height: 300px;
    overflow: hidden;
    display: inline-block;
    position: relative;
    margin: 0;
    .img {
      width: 100vw;
      height: 100vh;
      z-index: 1;
      position: absolute;
      display: inline-block;
      top: 0;
      left: 0;
      content: ' ';
      background-image: url('../static/sample-hero.png');
      background-attachment: fixed;
      background-size: cover;
    }
  }

  .type-header, .collection-header {
    height: 140px;
    position: sticky;
    top:0;
    width: 100%;
    z-index: 12;
    border-top: 0.5px solid var(--dark-border-color);
    border-bottom: 0.5px solid var(--dark-border-color);
    background: $bubble-white-gradient;
    padding:0;
    overflow: hidden;
    .shadow {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      height: calc(100%);
      z-index: 0;
    }
    .img {
      z-index: 1;
      width: 100vw;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      content: ' ';
      background-image: url('../static/sample-hero.png');
      background-attachment: fixed;
      background-size: cover;
      opacity: 1.0;
      @media (max-width: 767px) {
        background-attachment: unset;
      }
    }
  }

  .collection-header {
    z-index: 150;
    position: relative;
    border-top: 0;
    background-blend-mode: multiply;
    padding-left: 20px;
    padding-right: 20px;
    min-height: 300px;
    .img {
      @media (max-width: 767px) {

      }
    }
  }

  .type-header {
    top: 0;
    height: 110px;
    box-shadow: $light-gray-shadow;
    .img {
      opacity: 0.2;
    }
  }

  .type-tag, .collection-tag {
    transition: 0.4s;
    transform: translate(-50%, -50%);
    // opacity: 0;
    left: 50%;
    top: 50%;
    position: absolute;
    background: $green-gradient;
    color: $green-color;
    border: 0.5px solid $green-color;
    border-radius: 19px;
    display: flex;
    width: fit-content;
    flex-direction: row;
    justify-content: center;
    padding: 0px 10px 0px;
    z-index: 13;
    align-items: center;
    overflow: hidden;
    line-height: 1.1;
    box-shadow: $gray-shadow;
    img {
      width: 156px;
      position: absolute;
      left:-36px;
      top: -6px;
      max-height: unset;
    }
    h5 {
      padding-left: 10px;
      padding-right: 10px;
      height: 46px;
      margin: 0;
      line-height: 46px;
      font-weight: normal;
      font-size: 18px;
      text-align: center;
      color: $green-color;
    }
  }
  
  .collection-tag {
    display: flex;
    background: $yellow-gradient;
    color: var(--yellow-color);
    border: 0.5px solid var(--yellow-color);
    box-shadow: $small-gray-shadow;
    max-width: 400px;
    width: calc(100% - 40px);
    padding: 10px 0;
    padding-top: 12px;
    padding-right: 8px;
    // top: 50%;
    // transform: translateY(-50%);
    // left: 20px;
    margin: 0;
    top: unset;
    bottom: 30px;
    transform: translateX(-50%); 
    .img-container {
      flex: 0 0 80px;
    }
    span {
      font-size: 14px;
      margin-bottom: 6px;
    }
    h5 {
      color: var(--yellow-color);
      text-align: left;
      padding: 0;
      margin: 0;
      line-height: 1.1;
      height: unset;
      font-family: nimbus-sans-extended;
      font-weight: 800;
      margin-bottom: 2px;
      font-size: 20px;
      // text-transform: uppercase;
    }
  }

  .featured-products-container {
    width: 440px;
    position: absolute;
    top:340px;
    right: 30px;
    padding: 0;
    z-index: 100;
    .featured-products {
      display: flex;
      position: relative;
      width: 100%;
      height: 100%;
      flex-wrap: wrap;
      background:$transparent-gray-gradient;
      box-shadow: $gray-shadow;
      border: 1px solid #ae91f0;
      border-radius: 30px;
      max-height: 400px;
      .badge {
        position: absolute;
        top:0;
        left: 50%;
        transform: translate(-50%, -50%);
        color: var(--dark-blue-color);
        background: $blue-gradient;
        border: 0.5px solid var(--dark-blue-color);
        box-shadow: $gray-shadow;
        border-radius: 16px;
        padding: 8px 18px;
        font-size: 15px;
      }
      .transparent-product {
        flex: 0 1 50%;
        height: calc(50vh - 218px);
        max-height: 200px;
        transition: 0.2s;
        border-right: 0.5px solid #d7c7fc;
        border-bottom: 0.5px solid #d7c7fc;
        cursor: pointer;
        &:nth-child(2n - 1) {
          border-right: none;
        }
        &:nth-child(4), &:nth-child(5) {
          border-bottom: none;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          -webkit-filter: $filter-small-gray-shadow;
          filter: $filter-small-gray-shadow;
          padding: 14px;
          cursor: pointer;
        }
        &:hover {
        }
      }
      @media(max-width: 767px){
        flex-direction: column;
        margin: auto;
        padding: 0;
        padding-top: 75px;
        position: relative;
      }
    }
  }

  .product-list{
    // border-top: 0.5px solid #9279BE;
    width: 100%;
    position: relative;
    &:first-of-type {
      // display: none;
    }
    &.active{
      .type-tag{
        opacity: 1.0;

        @media(max-width: 767px){
          
         
          margin: 0px auto !important;

        }
      }
    }

  }


  .ProductPage-wrapper {
    border: none;
    height: 100%;
    width: 100%;
    .Product {
      display: none;
     
    }

    .Product__flex {
      margin: 0;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      @media(max-width: 767px){
        flex-direction: column;
        

        .carousel{
          
          min-height: unset;


        }
      }

      
    }
    .Product__carousel {
      background: white;
      img {
        background: white;
      }
    }

    .Product__thumbnails {
      flex: 0 1 55%;
      padding: 40px;
      display: flex;
      align-items: center;
      overflow-x: scroll;
      background:white;

      > img {
        width: 100%;
      }

      @media(max-width: 767px){
        display: none;
      }


    }

    .Product__more {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      .Product__ad {
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        flex-grow: 1;

      }
      .Product__classifications {
        display: flex;
        flex-direction: row;
        width: 100%;
        border-bottom: 0.5px solid var(--border-color);
        .Product__label {
          height: 38px;
          padding: 8px 30px;
          margin: 0;
          border: 0.5px solid var(--border-color);
          border-left: none;
          border-right: none;
        }
        .Product__collections {
          flex-grow: 1;
          border-right: 0.5px solid var(--border-color);
        }

        .Product__type {
          .Product__label {
            text-align: center;
          }
        }

        .Product__iconHolder {
          position: relative;
          width: 86px;

          cursor: pointer;
          img {
            width: 160px;
            left: -36px;
            object-fit: contain;
            max-width: unset;
            max-height: unset;
            position: absolute;
            bottom: -66px;
            transition: 0.2s;
          }
          overflow:hidden;
          &:hover {
            img {
              transform: translateY(-8px);
            }
          }
        }

        .Product__icons {
          height: 100px;
          display: flex;
          background: $gray-gradient;
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }

    .Product__info {
      position: sticky;
      top: 0;
      padding: 0;
      flex: 0 1 45%;
      height: 100vh;
      width: unset;
      border-bottom: none;
      background: $bubble-gradient;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      a{
        display: initial;
        position: absolute;
        height: 38px;
        width: 114px;
        background: linear-gradient(180deg, #7481F6 0%, #7367FF 100%);
  
      
          top: 30px;
          left: 30px;
          border-radius: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
          text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.75);

      }
      .Product__inner {
        padding: 30px;
        border-bottom: none;
        background: $bubble-gradient;
        padding-top: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        > div {
          width: 100%;
          max-width: 350px;
        }

        .Product__small {
          margin-bottom: 40px;
        }

        @media(max-width: 767px){
          align-items: center;

        }
      }

      .variantSelector {
        margin-bottom: 12px;
      }

      .Product__title {
        font-size: 20px;
        color: #6D6089;
        letter-spacing: 0.06rem;
        margin-bottom: 20px;
      }

      .Product__description, .Product__extra, .Product__label {
        color: #6E628A;
        font-size: 16px;
        line-height: 19.5px;
        margin-bottom: 26px;
        width: 100%;
        display: inline-block;
      }

      .Product__description {
        color: #494fe9;
      }

      .Product__extra {
        margin-bottom: 0;
      }

      .Product__price {
        display: none;
      }

      height: calc(100vh + 3px);
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;

      .Product__cta {
        display: flex;
      }
    }

    .Product__background {
      display: none;
    }

    @media (max-width: 767px){
      padding-bottom: 60px;
    }
  }
  /** end products **/


  /** extra **/
  .blue-color {
    color: rgba(134, 182, 255, 1);
  }

  .white-glow {
    color: white;
    text-shadow: 0px 0px 6px #FFFFFF;
  }

  .hidden {
    display: none;
  }


  .tienda-button{
    width: 100%;
    color: white;
    background: linear-gradient(180deg, #6473ff 0%, #8f5eff 100%);
    border: 0.5px solid #311269;
    box-sizing: border-box;
    box-shadow: $small-gray-shadow;
    display: flex;
    justify-content: space-between;
    height: 38px;
    font-size: 17px;
    transition: 0.1s;
    cursor: pointer;
    padding: 0px;
    border-radius: 12px;
    overflow: hidden;
    &:hover {
      filter: hue-rotate(-12deg);
    }
    .cta__text{
        display: flex;
        padding: 8px 13px;
        text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.5);
    }
    .price{
        background: linear-gradient(180deg, #5ED4A1 0%, #36be9c 100%);
        color: #3E856D;
        border-left: 0.5px solid #311269;
        padding: 5px 12px;
        padding-top: 9px;
        font-size: 16px;
        height: 100%;
    }

}

.modal {
  position: fixed;
  left: 50%;
  top: 50%;
  max-height: 60vh;
  max-width: 800px;
  width: calc(100% - 40px);
  transform: translate(-50%, -50%);
  z-index: 10000000;
  height: 100%;
  display: none;
  background: $dark-gray-gradient;
  box-shadow: $gray-shadow;
  border-radius: 16px;
  border: 0.5px solid var(--dark-border-color);
  overflow: scroll;
  color: #6E628A;
  p {
    padding:20px;
    padding-top: 0;
    font-size: 16px;
  }
  h1 {
    color: #6E628A;
    padding: 14px 20px;
    padding-bottom: 12px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    display: inline-block;
    background: $bubble-gradient;
    font-size: 24px;
    font-weight: normal;
    position: sticky;
    top: 0;
    border-bottom: 0.5px solid var(--border-color);
  }
  &.open {
    display: flex;
    flex-direction: column;
  }
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 20px;
    cursor: pointer;
    background: none;
    border: none;
    color: var(--dark-border-color);
  }
}

.footer{ 
  position: fixed;
  bottom: 0;
  right: 0;
  --adjusted-width: calc(var(--sidebar-width) - 3px);
  width: calc(100vw - var(--adjusted-width));
  max-height: 60px;
  z-index:150;
  display: flex;
  background: $bubble-white-gradient;
  border-top: 0.5px solid var(--dark-border-color);
  .footer-carousel {
    flex-grow: 1;
    flex-shrink: 1;
    background: none;
    position: relative;
    a {
      cursor: pointer;
      background: $bubble-white-gradient;
      padding: 12px 38px;
      padding-left: 44px;
      font-size: 14px;
      color: #a399bd;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;
      text-align: left;
      h3 {
        font-size: 15px;
        margin: 0;
        margin-bottom: 1px;
        font-weight: 500;
        line-height: 1.1;
        color: var(--dark-title-color);
      }
      .line {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin-top: 2px;
      }
      .date {
        color: #8388FE;
      }
    }
    .carousel-button {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border: 0;
      z-index:10;
      display: flex;
      align-items: center;
      justify-content: center;
      background:none;
      cursor: pointer;
      color: var(--dark-title-color);
      background: $bubble-white-gradient;
      border-right: 0.5px solid var(--dark-border-color);
      &:last-child {
        border-left: 0.5px solid var(--dark-border-color);
        border-right: none;
        right: 0;
        left: unset;
      }
    }
  }

  .footer-payments, .footer-copyright {
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $gray-gradient;
    border-left: 0.5px solid var(--dark-border-color);
  }

  .footer-payments {
    flex: 0 0 160px;
  }

  .footer-copyright {
    flex: 0 0 160px;
    color: var(--dark-title-color);
    font-size: 14px;
    justify-content: flex-start;
    line-height: 1.25;
    b {
      font-family: Archivo;
    }
    div {
      display: flex;
      opacity: 0.5;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  @media(max-width: 767px) {

    width: 100%;
    z-index: 1;
    .footer-carousel{
      display: none;
    }
    .footer-payments, .footer-copyright {
      flex: 0 0 50%;

    }
    
  }
}

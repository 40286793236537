*, *:before, *:after {
  box-sizing: border-box;
  appearance: none;
 }

html {
  font-size: 10px;
}

body {
  margin: 0;
  padding: 0;
  font-size:1rem;
  letter-spacing: 0.05em;
  color: black;
  background-attachment: fixed;
  /* border: 3px solid #3224a4; */
  /*background-color:#f1f2f5;*/
  background-repeat: no-repeat;
}

img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.App__sidebar {
  position: fixed;
  left: -1px;
  top: -1px;
  height: calc(100% + 2px);
  width: 121px;
  
  border: 0.5px solid #23242C;
  box-shadow: 140px 0px 328px rgba(163, 144, 196, 0.0562291), 58.49px 0px 137.031px rgba(163, 144, 196, 0.0807786), 31.27px 0px 73.2631px rgba(163, 144, 196, 0.1), 17.53px 0px 41.0707px rgba(163, 144, 196, 0.119221), 9.31px 0px 21.8123px rgba(163, 144, 196, 0.143771), 3.87px 0px 9.0766px rgba(163, 144, 196, 0.2);
  display: flex;
  flex-direction: column;
}

h1 {
  font-weight: 600;
  font-size: 80px;
  margin:0;
  line-height:1.5;
  color:black;
  cursor: default;
}

.middle-section.collection:hover .collection-select {
  display: flex;
}

.middle-section.collection:hover .bridge {
  display: flex;
}

.bridge {
  position: absolute;
  width: 200px;
  height:300px;
  display: none;
}

.collection-select {
  position: fixed;
  left: 140px;
  top: 180px;
  display: none;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 6px 12px;
  padding-bottom: 0px;
  background: linear-gradient(180deg, #FCFBFF 0%, #F7F3FF 98.44%);
  border: 0.5px solid #C2B1E1;
  box-sizing: border-box;
  
  border-radius: 17px;
  align-items: center;
  width: 220px;
  justify-content: space-between;
}

.collection-select .category-option {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin: 12px 0;
}

.middle-section.grow {
  flex-grow: 1;
}

.collection-select .category-option img {
  width: 65px;
  height: 60px;
  object-fit: contain;
  }

.type-select {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 24px;
}

.type-select img {
  width: 42px;
 
}

.footer-holder {
  display:flex;
  width:100%;
  margin-top:2.5rem;
  margin-bottom:1.5rem;
  padding: 0 1rem;
  justify-content: space-between;
  align-items: center;
}

.hero {
  flex-basis: 100%;
  display: flex;
  padding: 2rem;
  background-color: #7d8190;
  justify-content: space-between;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.hero .hero-left, .hero .hero-update {
  flex-basis: 31.33333%;
  display: flex;
  justify-content: space-between;
  margin: 1%;
  padding: 2rem;
  background-color: rgba(0,0,0,0.2);
  border-radius:15px;
  transition: 0.15s background-color;
}

.hero .hero-left:hover{
  background-color: rgb(15, 15, 15);
  background-image: url(./skull.png);
  background-size: 50px auto;
  background-repeat: no-repeat;
  background-position: center;
}


@keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hero .hero-left img {
  width: 75px;
  animation: infinite-spinning 5s infinite;
  animation-timing-function: linear;
}

.hero .hero-right {
  background-color: #f18c5b;
  border-radius:15px;
  flex-basis: 48%;
  padding: 2rem;
  margin:1%;
  font-size: 2rem;
  color: rgb(228, 79, 53);
 
}

.hero .hero-right h2{
  margin-bottom: 0;
}

.hero .hero-right p {
  margin: 0;
}

.sight {
  color: inherit;
}
.sight:hover{ 
  text-decoration:none;
}

.hero .hero-right h2 {
  margin-bottom: 0;
  font-size: 2.5rem;
  padding: 0.5rem 1.5rem;
  display: inline-block;
  border-radius: 10px;
  background-color: #b52229;
    color: rgba(255, 252, 252, 0.95)
}

.hero .hero-update {
  background-color: #2e2c34;
  height: fit-content;
  /* border: 3px solid #5252d1; */
  border-radius: 15px;
  padding:2rem;
  padding-top:1.1rem;
  color: #07003b;
  letter-spacing: 0.02em;
  display: initial;
  font-size: 20px;
 
}

.hero .hero-update b {
  color: rgba(255, 255, 255, 0.81);
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 1.0;
  margin-left: 0.5rem;
  
}

b {
}

.hero .hero-update .span {
  color: #6acea7;
  display:inline-block;
}

.ProductPage-wrapper {
  width:100%;
}

.empty {
  background-color: rgba(0,0,0,0.2);
  flex-grow: 1;
  padding: 1rem;
  border-radius: 10px;
  transition: 0.15s all;
}

.loading {
  width: 100%;
  margin: 2rem 0;
  color: black;
}

.view-all {
  background-color: #505169;
  color: rgb(222, 223, 239);
  border: none;
  /* font-family: nimbus-sans-extended, sans-serif; */
  letter-spacing: 0.05em;
  border-radius: 7px;
  font-size: 2rem;
  padding: 10px 17px;
  text-decoration: none;
  width: fit-content;
  /* flex-basis:280px; */
  text-align:center;
  margin-right: 2rem;
  cursor: pointer;
  transition: all 0.15s;
}

.view-all:hover,
.view-all:focus {
  background-color: #363146;
  color: rgb(255, 251, 253);
}

.App__subtitle {
  color: rgb(0, 57, 172);
  border-radius:10px;
  padding: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  position: absolute;
  bottom: 34px;
  right: 5rem;
  z-index:2;
  transition: transform 0.15s;
}

.App__subtitle:hover{
  transform: translate(0, -5px);
}

h2 {
  font-style: normal;
  margin: 0;
  letter-spacing: 0.05em;
  font-weight:500;
  font-size: 2.5rem;
  cursor:default;
}

fieldset{
 margin-top: 2rem;
 border:2px solid rgba(0,0,0,0.1);
 border-radius:10px;
 font-size: 2rem;
 color: #45464b;
 padding: 0rem 2rem;
 padding-bottom: 1.5rem;
}

.line-below {
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 2px solid rgba(0,0,0,0.1);
}

.hero-update-section {
  background-color: #060607;
  color: #ffb222;
  border-radius: 5px;
  margin-top: 0.75rem;
  padding: 0.5rem 1.0rem;
}

.hero-right .hero-update-section {
  background-color: #c403033b;
  color: #16161a;
  margin-top:1rem;
}

legend{
 color:#0a0910;
}

.selectors select {
  margin-right: 1em;
  font-size: 1.5rem;
  background-color: white;
  border-radius: 5px;
  border: 0;
  padding: 0.5rem;
}

/* fuck you */

.table{
 width:100%;
 padding:0;
 margin:0;
}

.left{
 vertical-align: top;
 width:80px;
}

.left img{
 width:30px;
 position: fixed;
 top:40px;
 left:40px;
}

.left a{
 opacity: 0.5;
}

.left a:hover{
 opacity:1.0;
}

.right{
 padding-top:1.25rem;
}

.footer-holder-left, .footer-holder-right {
  display:flex;
  height:fit-content;
}

#fixed {
  height: 50px;
}

#fixed .header-flex{
  position: fixed;
  width: 100%;
  top:0;
  z-index: 2;
  padding: 0 35px;
  left: 0;
}

#fixed .tienda {
  top: 0;
  border-radius: 0;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-top: 0;
  padding-top: 0.1rem;
  background: none;
  background-color: #e7e9f3;
  
}

#fixed .App__view-cart-wrapper {
  top: 0;
  margin-right: 31px;
  border-radius: 0px;
  border-radius: 0;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
 
}

#fixed .App__view-cart-wrapper .button {
  border-radius: 0;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  background:none;
  background-color: #148a74;
}

#fixed .header-flex {
  margin-top: 0;
}

.left-footer {
  border-radius:7px;
  background-color: #e87b64;
  padding: 0.45rem 1rem;
  margin-left: 1.5rem;
  font-size:2.5rem;
  text-transform: lowercase;
  transition: all 0.15s;

}

.left-footer a{
  text-decoration: none;
  color: #860701;
  padding:1rem;
  width:100%;
  font-weight:300;
  font-size: 26px;
 }


.footer a{
 text-decoration: none;
 color:#D37F00;
 padding:1rem;
 width:100%;
 font-weight:300;
}

.insta-footer {
  z-index: 2;
  border-radius:7px;
  background-color: rgb(188, 115, 221);
  padding: 0.75rem;
  font-size:3rem;
  text-transform: lowercase;
  transition: all 0.15s;
  
}

.insta-footer img {
  width: 30px;
}

.insta-footer:hover{
  background-color:rgb(206, 139, 250);
}

.twitter-footer {
  z-index: 2;
  border-radius:7px;
  background-color: rgb(95, 200, 204);
  padding: 0.75rem 1.0rem;
  margin-left: 1.5rem;
  font-size:3rem;
  text-transform: lowercase;
  transition: all 0.15s;
  
}

.twitter-footer img {
  width: 23px;
}

.twitter-footer:hover{
  background-color:rgb(89, 228, 221);
}

.left-footer a span{
  font-family: Archivo, sans-serif;
  font-weight:300;
}

.left-footer:hover{
  background-color: rgb(240, 96, 77);
}

.footer-space {
  height:6rem;
}

sup{
 font-weight: 100;
 opacity: 0.25;
}


.App__customer-actions {
  float: left;
  padding: 10px;
}

::-webkit-scrollbar {
  display: none;
}

.section-title {
  /* background-color: rgba(0,0,0,0.2); */
  /* border-bottom-left-radius: 7px; */
  /* border-bottom-right-radius: 7px; */
  padding: 1rem 0rem;
  width:100%;
  text-align:center;
  z-index:1;
  background-color: #4a4754;
  
}

.section-title h2 {
  font-size: 2rem;
  color:#eae8f4;
  font-weight: 700;
  opacity:0.95;
}


.login {
  font-size: 1.2rem;
  color: #b8b8b8;
  cursor: pointer;
}

.login:hover {
  color: white;
}

.Flash__message-wrapper {
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  align-items: flex-end;
  justify-content: center;
  position: fixed;
  bottom: 0;
  pointer-events: none;
  z-index: 227;
  left: 50%;
  transform: translateX(-50%);
}

.Flash__message {
  background: rgba(0,0,0,0.88);
  border-radius: 3px;

  color: #ffffff;
  cursor: default;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: none;
  pointer-events: auto;
  position: relative;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  padding: 10px 20px;
  margin: 0;
}

.Flash__message--open {
  display: flex;
}

/* CART
 * ============================== */


/* CUSTOMER AUTH
 * ============================== */
.CustomerAuth {
  background: #2a2c2e;
  display: none;
  height: 100%;
  left: 0;
  opacity: 0;
  padding: 0 0 65px;
  top: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  transition: opacity 150ms;
  opacity: 1;
  visibility: visible;
  z-index: 1000;
  position: fixed;
}

.CustomerAuth--open {
  display: block;
}

.CustomerAuth__close {
  position: absolute;
  right: 9px;
  top: 8px;
  font-size: 35px;
  color: #999;
  border: none;
  background: transparent;
  transition: transform 100ms ease;
  cursor: pointer;
}

.CustomerAuth__body {
  padding: 130px 30px;
  width: 700px;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  position: relative;
}

.CustomerAuth__heading {
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 15px;
}

.CustomerAuth__credential {
  display: block;
  position: relative;
  margin-bottom: 15px;
  border-radius: 3px;
}

.CustomerAuth__input {
  height: 60px;
  padding: 24px 10px 20px;
  border: 0px;
  font-size: 18px;
  background: #fff;
  width: 100%;
}

.CustomerAuth__submit {
  float: right;
}

.error {
  display: block;
  font-size: 15px;
  padding: 10px;
  position: relative;
  min-height: 2.64286em;
  background: #fbefee;
  color: #c23628;
}

.carousel .slide {
  background-color:rgba(0, 0, 0, 0);
}
